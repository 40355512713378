import React from "react";

import NewsList from "../ui/news/NewsList";
import Layout from "../components/Layout";

const NewsIndexTemplate = (props) => {
  const { pageContext } = props;
  const { news } = pageContext;

  const newsList = <NewsList news={news.filter((n) => n.publish)} />;

  return <Layout children={newsList} />;
};

export default NewsIndexTemplate;
